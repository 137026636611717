import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import {
  logout,
  isLogged,
  isUserLoading,
} from "../../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../../../components/hibimComponents/Avatar";
import {useTrad} from "../../../utility/traduction";

const UserDropdown = ({ currentUser }) => {
  const dispatch = useDispatch();
  const handleLogout = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(logout());
    },
    [dispatch]
  );

  const t = useTrad("HibimAccountAdmin_header")
  const user = useSelector(state => state.auth?.user)
  const lang = useSelector(state => state.traduction?.lang)
  

  return (
    <DropdownMenu right style={{ minWidth: "250px" }}>
      <DropdownItem header>
        <span className="align-middle">
          Profile -{" "}
          {currentUser && currentUser.roles.indexOf("ROLE_ADMIN") !== -1
            ? "Admin"
            : "User"}{" "}
          (u {currentUser ? currentUser.id : "unknown"})
        </span>
      </DropdownItem>
       <DropdownItem
        tag="a"
        href={`${process.env.REACT_APP_HIBIM_URL}/${lang}/u/${user?.userProfile?.id}`}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">{t("myProfile","Mon profil")}</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href={`${process.env.REACT_APP_ACCOUNTS_URL}/create?redirect=${encodeURIComponent(
            window.location.href
          )}`}
      >
        <Icon.MapPin size={14} className="mr-50" />
        <span className="align-middle">{t("addSpace","Ajouter un space")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href={`${process.env.REACT_APP_ACCOUNTS_URL}/join?redirect=${encodeURIComponent(
            window.location.href
          )}`}>
        <Icon.PlusCircle size={14} className="mr-50" />
        <span className="align-middle">{t("joinSpace","Rejoindre un space")}</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href={`${process.env.REACT_APP_ACCOUNTS_URL}/invite?redirect=${encodeURIComponent(
            window.location.href
          )}`}
      >
        <Icon.UserPlus size={14} className="mr-50" />
        <span className="align-middle">{t("inviteUser","Inviter un utilisateur")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={handleLogout}>
        <Icon.LogOut size={14} className="mr-50" />
        <span className="align-middle">{t("disconnect","Déconnexion")}</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = () => {
  const { currentUser } = useSelector((state) => ({
    logged: isLogged(state),
    userLoading: isUserLoading(state),
    currentUser: state.auth.user,
  }));

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {currentUser
                ? `${currentUser.firstname} ${currentUser.lastname}`
                : null}
            </span>
            <span className="user-status">
              {currentUser ? currentUser.email : null}
            </span>
          </div>
          <span className="user avatar m-0">
            <Avatar
              src={currentUser && currentUser.photo ? currentUser.photo.url : null}
              size="40px"
              alt={
                currentUser 
                  ? `${currentUser.firstname[0].toUpperCase()}${currentUser.lastname[0].toUpperCase()}`
                  : null
              }
            />
          </span>
        </DropdownToggle>
        <UserDropdown currentUser={currentUser} />
      </UncontrolledDropdown>
    </ul>
  );
};

export default NavbarUser;
