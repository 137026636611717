import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "account",
    title: "Compte",
    type: "dropdown",
    icon: <Icon.Calendar size={16} />,
    children: [
      {
        id: "accountSettings",
        title: "Compte",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/account/settings",
      },
      {
        id: "preferences",
        title: "Préférences",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/account/preferences",
      },
       {
        id: "payment",
        title: "Paiement",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/account/payment",
      }
    ]
  },
  {
    id: "myspace",
    title: "My Space",
    type: "dropdown",
    icon: <Icon.Calendar size={16} />,
    children: [
      {
        id: "parameters",
        title: "Paramètres",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/myspace/parameters",
      },
      {
        id: "workspace",
        title: "Espace de travail",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/myspace/workspace"
      },
      {
        id: "member",
        title: "Membre",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/myspace/member"
      },
      {
        id: "facturation",
        title: "facturation",
        type: "item",
        icon: <Icon.Calendar size={10} />,
        navLink: "/myspace/facturation"
      }
    ]
  }
]

export default horizontalMenuConfig
