import React from "react"
import "boxicons"
import {useSelector} from "react-redux"

const Icon = (props) => {
	const theme = useSelector(state => state.customizer.theme)
	let {type, active} = props; // menu, submenu
	let defaultColor, activeColor;
	if(type === "menu")
	{
		defaultColor = ["dark", "semi-dark"].includes(theme) ? "#c2c6dc" : "#565656"
		activeColor = ["dark", "semi-dark"].includes(theme) ? "white" :"#4889E3"
	}
	else
	{
		defaultColor = ["dark"].includes(theme) ? "#c2c6dc" : "black"
		activeColor = "#4889E3";
	}

	
	return <box-icon {...props} color={active ? activeColor : defaultColor}></box-icon>
}

export default Icon