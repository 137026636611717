import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import underMaintenance from "../../assets/img/pages/maintenance-icon.png";
import bg from "../../assets/img/pages/maintenancebg.jpg";
import {Wrapper} from "./Wrapper"


const Maintenance = () => {
  return (
    <Wrapper bg={bg}>
      <Row className="m-0 pb-5">
        <Col sm="12">
          <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
            <CardBody className="text-center">
              <img
                src={underMaintenance}
                alt="underMaintenance"
                className="img-fluid align-self-center mt-75"
                width="280px"
              />
              <h1 className="font-large-2 my-1">Le site est en maintenance !</h1>
              <Row className="d-flex justify-content-center"> 
                <Col
                  xs="12"
                  sm="9"
                  md="7"
                  lg="5"
                >
                  <p className="pt-2 mb-0">
                    Labore ullamco cillum nostrud occaecat voluptate eu aute non dolor adipisicing et qui consectetur cupidatat esse ullamco et in.
                  </p>
                   
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Maintenance;
