import { CLEAR, SET_SERVICE_OPTIONS, SET_CURRENT_SERVICE, FETCH_SERVICE } from "../../actions/service";

const space = (state = { isFetching: false, currentService: {}, serviceOptions: []}, action) => {
  switch (action.type) {
    case FETCH_SERVICE: {
      switch (action.status) {
        case "fetching":
          return { ...state, isFetching: true };
        case "success": {
          return { ...state, isFetching: false };
        }
        case "error":
          return { ...state, isFetching: false, error: action.error };
        default:
          return state;
      }
    }

    case SET_CURRENT_SERVICE: {
      return {
        ...state,
        currentService: action.service,
      };
    }

    case SET_SERVICE_OPTIONS: {
      return { ...state, serviceOptions: action.serviceOptions };
    }

    case CLEAR:
      return {
        ...state,
        creating: false,
        error: undefined,
      };
    default:
      return state;
  }
};

export default space;
