export const pageToUrl = {
	dashboard: "/",
	myspace: "/myspace",
	notification: "/notification",
};

export const acceptedLocales = [
	"fr",
	"en",
	"es",
	"de",
	"it",
	"pt",
	// "ar",
	// "bg",
	// "hr",
	// "da",
	// "ph",
	// "fi",
	// "hu",
	// "id",
	// "ga",
	// "nl",
	// "no",
	// "pl",
	// "ro",
	// "ru",
	// "sr",
	// "sk",
	// "sv",
	// "cs",
	// "tr",
	// "uk",
];

// ISO 639-1 : {key : codes from https://flagicons.lipis.dev/}
export const langsToIcons = {
	fr: { key: "fr", text: "Français" },
	en: { key: "gb", text: "Anglais" },
	es: { key: "es", text: "Espagnol" },
	de: { key: "de", text: "Allemand" },
	it: { key: "it", text: "Italien" },
	pt: { key: "pt", text: "Portugais" },
	// ar: { key: "ma", text: "Arabe" },
	// bg: { key: "bg", text: "Bulgare" },
	// hr: { key: "hr", text: "Croate" },
	// da: { key: "dk", text: "Danois" },

	// ph: { key: "ph", text: "Filipino" },
	// fi: { key: "fi", text: "Finnois" },

	// hu: { key: "hu", text: "Hongrois" },
	// id: { key: "id", text: "Indonésien" },
	// ga: { key: "ie", text: "Irlandais" },

	// nl: { key: "nl", text: "Néerlandais " },
	// no: { key: "no", text: "Norvégien" },
	// pl: { key: "pl", text: "Polonais" },

	// ro: { key: "ro", text: "Roumain" },
	// ru: { key: "ru", text: "Russe" },
	// sr: { key: "rs", text: "Serbe" },
	// sk: { key: "sk", text: "Slovaque" },
	// sv: { key: "se", text: "Suédois" },
	// cs: { key: "cz", text: "Tchèque" },
	// tr: { key: "tr", text: "Turc" },
	// uk: { key: "ua", text: "Ukrainien" },
};

export const languages = [
	{ value: "en", label: "Anglais" },
	{ value: "fr", label: "Français" },
	{ value: "es", label: "Espagnol" },
	{ value: "it", label: "Italien" },
	{ value: "pt", label: "Portugais" },
];
