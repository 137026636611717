import { combineReducers } from "redux";
import customizer from "./customizer";
import auth from "./auth";
import spaceReducer from "./space"
import traduction from "./traduction"
import service from "./service"

const rootReducer = combineReducers({
  customizer,
  auth,
  spaces: spaceReducer,
  traduction,
  service
});

export default rootReducer;
