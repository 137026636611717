const traductionReducer = (state = {lang:"fr"}, action) => {
  switch (action.type) {
    case "CHANGE_LANG":
      return { ...state, lang: action.payload };  
    default:
      return state;
  }
};

export default traductionReducer;
