// tooling modules
import axios from "axios";
import Cookies from "js-cookie";

// configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let token = Cookies.get("token");
if (token) api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default api;
