import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Archive, ChevronDown, MapPin, PlusCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSpace, logoutSpace as logoutSpaceAction } from "../../../../../redux/actions/space";
import { useTrad } from "../../../../../utility/traduction";
const SidebarSpaces = ({collapsed}) => {
  const t = useTrad("HibimAccountAdmin_menu_SidebarSpaces");
  const [logoutSpace, setLogoutSpace] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const spaces = useSelector(state => state.spaces.list);
  const currentSpace = useSelector(state => state.spaces.currentSpace);
  const dispatch = useDispatch();

  const changeCurrentSpace = space => {
    dispatch(setCurrentSpace(space));
  };

  const handleLogout = async () => {
    if (logoutSpace) {
      await dispatch(logoutSpaceAction(logoutSpace));
      setIsModal(false);
    }
  };

  return (
    <div className="space-action d-block px-1">
      <Modal isOpen={isModal} centered toggle={() => setIsModal(false)}>
        <ModalHeader>
          {t("logoutOf", "Deconnexion de")} {logoutSpace && logoutSpace.name}
        </ModalHeader>
        <ModalBody>
          {t("modalBody1", "Voulez vous réellement vous déconnecter de cet Espace?")} <br />
          {t("modalBody2", "Vous ne pourrez plus accéder à l'ensemble des informations présentes dessus.")}
        </ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={() => setIsModal(false)}>
            {t("cancel", "Annuler")}
          </Button>
          <Button color="primary" onClick={handleLogout}>
            {t("disconnect", "Se Déconnecter")}
          </Button>
        </ModalFooter>
      </Modal>
      {!collapsed && (
        <UncontrolledDropdown>
          <DropdownToggle color="tag primary" className="w-100">
            <div className="d-flex align-items-center">
              <Archive size={20} />
              <span className="ml-1 mr-auto text-bold-600">{currentSpace && currentSpace.name}</span>
              <ChevronDown size={20} />
            </div>
          </DropdownToggle>
          <DropdownMenu right style={{ maxWidth: "230px" }}>
            <DropdownItem header>{t("mySpaces", "Mes Spaces")}</DropdownItem>
            {spaces.map((space, id) => (
              <DropdownItem
                toggle={false}
                key={id}
                tag="a"
                className="d-flex align-items-center"
                onClick={() => changeCurrentSpace(space)}
              >
                <div className="mr-50">
                  <Archive size={15} />
                </div>
                <span className="truncate">{space.name}</span>
                {/* <div
                className=" align-items-center ml-auto"
                onClick={() => {
                  setLogoutSpace(space);
                  setIsModal(true)}}
              >
                <LogOut size={15} />
              </div> */}
              </DropdownItem>
            ))}
            <DropdownItem header>{t("action", "Action")}</DropdownItem>
            <DropdownItem
              tag="a"
              className="d-flex align-items-center"
              href={`${process.env.REACT_APP_ACCOUNTS_URL}/create?redirect=${encodeURIComponent(window.location.href)}`}
            >
              <MapPin size={15} className="mr-50" />
              <span className="truncate">{t("addSpace", "Ajouter un space")}</span>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className="d-flex align-items-center"
              href={`${process.env.REACT_APP_ACCOUNTS_URL}/join?redirect=${encodeURIComponent(window.location.href)}`}
            >
              <PlusCircle size={14} className="mr-50" />
              <span>{t("joinSpace", "Rejoindre un space")}</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );
};

export default SidebarSpaces;
