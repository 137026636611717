import React, { Suspense, lazy } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { history } from "./history";
import Spinner from "./components/@vuexy/spinner/Loading-spinner";
import { ContextLayout } from "./utility/context/Layout";
import { useSelector } from "react-redux";
import NewWorkSpace from "./views/myspace/workspace/NewWorkSpace";

const Settings = lazy(() => import("./views/account/settings/Settings"));
const Preferences = lazy(() =>
  import("./views/account/preferences/Preferences")
);
const Payment = lazy(() => import("./views/account/payment/Payment"));
const Parameters = lazy(() => import("./views/myspace/parameters/Parameters"));
const WorkSpace = lazy(() => import("./views/myspace/workspace/WorkSpace"));
const AddMethodPayment = lazy(() =>
  import("./views/account/payment/AddMethodPayment")
);
const Error401 = lazy(() => import("./views/misc/error/401"));
const Error403 = lazy(() => import("./views/misc/error/403"));
const Error404 = lazy(() => import("./views/misc/error/404"));
const Error500 = lazy(() => import("./views/misc/error/500"));
const Maintenance = lazy(() => import("./views/misc/Maintenance"));

// Set Layout and Component Using App Route
const AppRoute = ({
  component: Component,
  fullLayout,
  permission,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <ContextLayout.Consumer>
          {(context) => {
            let LayoutTag =
              fullLayout === true
                ? context.fullLayout
                : context.state.activeLayout === "horizontal"
                ? context.horizontalLayout
                : context.VerticalLayout;
            return (
              <LayoutTag {...props}>
                <Suspense fallback={<Spinner />}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            );
          }}
        </ContextLayout.Consumer>
      );
    }}
  />
);

const AppRouter = () => {
  const currentSpace = useSelector((state) => state.spaces.currentSpace);
  const currentUser = useSelector(state => state.auth.user)

  return (
    // Set the directory path if you are deploying in sub-folder
    <Router history={history}>
      <Switch>
        <AppRoute exact path="/" component={Settings} />

        <AppRoute path="/account/settings" component={Settings} />
        <AppRoute path="/account/preferences" component={Preferences} />
        <AppRoute path="/account/payment" component={Payment} />

        <Route exact path="/myspace/parameters">
          {currentSpace.userRole === "admin" || (currentUser && currentSpace.user == currentUser["@id"]) ? (
            <AppRoute path="/myspace/parameters" component={Parameters} />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        <AppRoute path="/myspace/workspace" component={WorkSpace} />
        <Route exact path="/myspace/new-workspace" component={NewWorkSpace} />

        <Route
          exact
          path="/account/add-payment-method"
          component={AddMethodPayment}
        />

        <Route path="/error-401" component={Error401} />
        <Route path="/error-403" component={Error403} />
        <Route path="/error-404" component={Error404} />
        <Route path="/error-500" component={Error500} />
        

 {/* 
Toggle maintenance mode : two methods 
1/ Comment all other routes and remove maintenance path (so that it is triggered on every route)
2/ Add env variable (REACT_APP_MAINTENANCE_MODE) and verify if maintenance mode is active
upstream on index
*/}

        <Route path="/maintenance" component={Maintenance} />

        <Route component={Error404} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
