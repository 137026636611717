import axios from "../../../utility/api";

export const FETCH_SPACES = "FETCH_SPACES";
export const SET_CURRENT_SPACE = "SET_CURRENT_SPACE";
export const SET_CURRENT_SPACE_BY_ID = "SET_CURRENT_SPACE_BY_ID";
export const LOGOUT_SPACE = "LOGOUT_SPACE";
export const SET_SPACES = "SET_SPACES";
export const UPDATE_CURRENT_SPACE = "UPDATE_CURRENT_SPACE";
export const REMOVE_SPACE = "REMOVE_SPACE";

export const setCurrentSpace = space => {
  return { type: SET_CURRENT_SPACE, space };
};


export const setCurrentSpaceById = spaceId => {
  return { type: SET_CURRENT_SPACE_BY_ID, payload: spaceId };
};

export const logoutSpace = space => {
  return async dispatch => {
    dispatch({ type: LOGOUT_SPACE, status: "fetching" });
    try {
      // LOGOUT

      dispatch({
        type: LOGOUT_SPACE,
        status: "success",
        data: space,
      });
    } catch (error) {
      dispatch({ type: LOGOUT_SPACE, status: "error", error });
      return error;
    }
  };
};

export const setSpaces = (spaces, currentSpace) => {
  return { type: SET_SPACES, spaces, currentSpace };
};

export const fetchSpaces = currentSpace => {
  return async (dispatch, getState) => {
    const user = getState().auth.user;

    if (user) {
      dispatch({ type: FETCH_SPACES, status: "fetching" });

      try {
        const res = await axios
          .get(`/api/space?user=${user.id}`)
          .then(res => res.data)
          .then(res => res["hydra:member"]);

        const spaces = transformSpaces(res, getState);
        // console.log(spaces);

        dispatch({
          type: FETCH_SPACES,
          status: "success",
        });

        dispatch(setSpaces(spaces, currentSpace));

        return spaces;
      } catch (error) {
        dispatch({ type: FETCH_SPACES, status: "error", error });
        return error;
      }
    }
  };
};

export const updateCurrentSpace = data => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_SPACE, status: "fetching" });
    let currentSpace = getState().spaces.currentSpace;
    let formData = Object.keys(data).reduce((acc, key) => {
      if (data[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {}); // only non falsy values
    console.log("updateCurrentSpace data", formData);
    await axios.put("/api/space/" + currentSpace.id, formData);
    dispatch({ type: UPDATE_CURRENT_SPACE, data, status: "success" });
  } catch (e) {
    console.log("error updateCurrentSpace", e?.response?.data);
    dispatch({ type: UPDATE_CURRENT_SPACE, error: e, status: "error" });
    throw new Error("Error updating Space");
  }
};

export const deleteSpaces = space_ids => async (dispatch, getState) => {
  try {
    for (var i = 0; i < space_ids.length; i++) {
      let id = space_ids[i];
      await axios.delete("/api/space/" + id);
      dispatch({ type: REMOVE_SPACE, id, status: "success" });
    }
  } catch (e) {
    console.log("error deleting space", e);
  }
};

export const quitSpaces = space_ids => async (dispatch, getState) => {
  try {
    const userId = getState().auth.user.id;
    for (var i = 0; i < space_ids.length; i++) {
      let id = space_ids[i];
      await axios.delete(`/api/space/${id}/member/${userId}`);
      dispatch({ type: REMOVE_SPACE, id, status: "success" });
    }
  } catch (e) {
    console.log("error quitting space", e);
  }
};

const transformSpaces = (data, getState) => {
  const userID = getState().auth.user ? getState().auth.user.id : null;
  const spaces = data.map(elem => {
    // retrieve elem id from string "/api/space/{id}"
    let spaceId = parseInt(elem["@id"].match(/\d+/g)[0]);
    let spaceUserId = parseInt(elem["user"].match(/\d+/g)[0]);
    elem.id = spaceId;
    elem.type = elem["@type"];
    elem.userRole = spaceUserId === userID ? "admin" : "member";
    delete elem["@id"];
    delete elem["@type"];
    return elem;
  });
  return spaces;
};
