import React from "react";
import { User } from "react-feather";
import styled from "styled-components";
import "boxicons";

const Avatar = ({
  src,
  alt,
  className,
  size,
  componentProps = {},
  ...props
}) => {
  if (src) {
    return (
      <div className={`avatar m-0 ${className ? className : ""}`} {...props}>
        <img
          src={src}
          alt={alt ? alt : ""}
          width={size}
          height={size}
          {...componentProps}
        />
      </div>
    );
  }

  if (alt) {
    return (
      <div
        className={`d-flex justify-content-center rounded-circle bg-light align-items-center ${
          className ? className : ""
        }`}
        style={{ width: size, height: size }}
      >
        <span className="font-weight-bold">{alt}</span>
      </div>
    );
  }

  return (
    <div className={`avatar m-0 ${className ? className : ""}`} {...props}>
      <User style={{ width: size, height: size }} {...componentProps} />
    </div>
  );
};

const StyledAvatar = styled(Avatar)`
  font-size: 35px;
  color: white;
`;


let AvatarEdit = props => {
  return (
    <div {...props}>
      <StyledAvatar
        src={props.avatar?.url}
        size="120px"
        alt={props.defaultText}
      />
      <div className="edit-icon" onClick={props.handleClick}>
        <box-icon name="pencil" size="22px"></box-icon>
      </div>
    </div>
  );
};

const StyledAvatarEdit = styled(AvatarEdit)`
position: relative;
width:120px;
height:auto;
.edit-icon {
  position: absolute;
  bottom:0px;
  right:0px;
  border-radius: 100px;
  border: 1px grey solid;
  padding: 5px;
  background-color:white;
  width:40px;
  height:40px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
}
`
export {StyledAvatarEdit as AvatarEdit}
export default Avatar;
