import styled from "styled-components";

export const Wrapper = styled.div`
  background: url(${props => props.bg}) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  height:100vh;
`;

// style={{paddingTop:"150px",paddingBottom:"150px"}} 

