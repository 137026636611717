import {
  FETCH_SPACES,
  SET_CURRENT_SPACE,
  SET_CURRENT_SPACE_BY_ID,
  LOGOUT_SPACE,
  SET_SPACES,
  UPDATE_CURRENT_SPACE,
  REMOVE_SPACE,
} from "../../actions/space";

const spaceReducer = (state = { isFetching: false, list: [], currentSpace: {} }, action) => {
  switch (action.type) {
    case FETCH_SPACES: {
      switch (action.status) {
        case "fetching":
          return { ...state, isFetching: true };
        case "success": {
          return { ...state, isFetching: false };
        }
        case "error":
          return { ...state, isFetching: false, error: action.error };
        default:
          return state;
      }
    }

    case SET_CURRENT_SPACE: {
      if (state.list.includes(action.space)) {
        return {
          ...state,
          currentSpace: { ...state.currentSpace, ...action.space },
        };
      } else {
        return state;
      }
    }

    case SET_CURRENT_SPACE_BY_ID: {
      let space = state.list.find(s => s.id === action.payload);
      return space ? {...state, currentSpace:space} :state;

    }
    case UPDATE_CURRENT_SPACE: {
      if (action.status === "success") {
        let newCurrentSpace = { ...state.currentSpace, ...action.data };

        // update spaces list as well
        let spaces = state.list.map(space => {
          if (space.id === newCurrentSpace.id) {
            return { ...space, ...newCurrentSpace };
          } else {
            return space;
          }
        });
        return {
          ...state,
          currentSpace: newCurrentSpace,
          list: spaces,
        };
      } else return state;
    }

    case LOGOUT_SPACE: {
      switch (action.status) {
        case "fetching":
          return { ...state, isFetching: true };
        case "success":
          return {
            ...state,
            list: state.list.filter(elem => elem !== action.data),
            isFetching: false,
          };
        case "error":
          return { ...state, isFetching: false, error: action.error };
        default:
          return state;
      }
    }

    case SET_SPACES: {
      let newListSpaces = action.spaces;
      if (newListSpaces.includes(state.currentSpace)) return { ...state, list: newListSpaces };
      else {
        let currentSpace = newListSpaces.find(s => s.slug === action.currentSpace) || newListSpaces[0] || {};
        return { ...state, list: newListSpaces, currentSpace };
      }
    }

    case REMOVE_SPACE: {
      let id = action.id;
      let newSpaces = state.list.filter(elem => elem.id !== id);
      console.log("reducer remove space, new spaces", newSpaces);
      let newCurrentSpace = state.currentSpace;
      if (newCurrentSpace.id === id || newSpaces.length === 0) {
        newCurrentSpace = newSpaces[0] || {};
      }
      console.log("reducer remove space, new current space", newCurrentSpace);
      return { ...state, list: newSpaces, currentSpace: newCurrentSpace };
    }
    default:
      return state;
  }
};

export default spaceReducer;
