import React, { useCallback, useEffect } from "react";
import Router from "./Router";
import "./components/@vuexy/rippleButton/RippleButton";

import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import { useSelector, useDispatch } from "react-redux";
import { authPending, isLogged, authenticate } from "./redux/actions/auth";
import { fetchSpaces } from "./redux/actions/space";
import { fetchThemeConfig } from "./redux/actions/customizer";
import { changeLang } from "./redux/actions/traduction";
import { fetchService } from "./redux/actions/service";
import {acceptedLocales} from "./configs/traduction"


const App = props => {
  const { pending, logged, auth } = useSelector(state => ({
    pending: authPending(state),
    logged: isLogged(state),
    auth: state.auth,
  }));

  const dispatch = useDispatch();
  const handleAuthentication = useCallback(() => dispatch(authenticate()), [dispatch]);
  const spaceParam = new URLSearchParams(window?.location?.search).get("space");


  // TODO: uncomment this when preprod is ok
  useEffect(() => {
    if (pending === undefined && logged === undefined) {
      // console.log("use effect handleAuthentication")
      handleAuthentication();
    }
    if (pending === false && logged === false) {
      if (process.env.REACT_APP_ACCOUNTS_URL) {
        window.location.replace(
          `${process.env.REACT_APP_ACCOUNTS_URL}?redirect=${encodeURIComponent(window.location.href)}`,
        );
      }
    }
  }, [pending, logged, handleAuthentication]);

  useEffect(() => {
    // fetch spaces & theme config only if user is logged in and current user exists
    if (logged && auth?.user) {
      console.log("use effect : fetch spaces & theme config");
      dispatch(fetchSpaces(spaceParam));
      dispatch(fetchThemeConfig());

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, auth, dispatch]);

  // fetch user services (fields to show or not on his profile)
  // if he has a profession
   useEffect(() => {
    if (auth?.user?.userProfile?.profession) {
      dispatch(fetchService(auth.user.userProfile.profession));
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (auth?.user?.languages) {
      let locale = auth.user.languages[0];
      if (locale && acceptedLocales.includes(locale)) {
        dispatch(changeLang(locale));
      }
    }
  }, [dispatch, auth]);

  return <Router />;
};

export default App;
