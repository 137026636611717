import React from "react"
import * as Icon from "react-feather"
import "boxicons"
const navigationConfig = [
  {
    id: "returnSpace",
    title: "Retour à My Space",
    type: "external-link",
    iconName:'chevron-left',
    icon: <box-icon name='chevron-left' color="#565656"></box-icon>,
    navLink: process.env.REACT_APP_SPACE_URL
  },
  {
    type: "groupHeader",
    id:"account",
    groupTitle: "COMPTE"
  },
  {
    id: "accountSettings",
    title: "Compte",
    type: "item",
    iconName:'images',
    icon: <box-icon name='images' color="#565656"></box-icon>,
    navLink: "/account/settings"
  },
  {
    id: "preferences",
    title: "Préférences",
    type: "item",
    iconName:"palette",
    icon: <box-icon name='palette' color="#565656"></box-icon>,
    navLink: "/account/preferences"
  },
  {
    id: "payment",
    title: "Paiement",
    type: "item",
    iconName:"credit-card-alt",
    icon: <box-icon name='credit-card-alt' color="#565656"></box-icon>,
    navLink: "/account/payment"
  },
  {
    id:"myspace",
    type: "groupHeader",
    groupTitle: "My Space"
  },
  {
    id: "parameters",
    title: "Paramètres",
    type: "item",
    iconName:'cog',
    icon: <box-icon type='solid' name='cog' color="#565656"></box-icon> ,
    navLink: "/myspace/parameters"
  },
  {
    id: "workspace",
    title: "Espace de travail",
    type: "item",
    iconName:'collection',
    icon: <box-icon type='solid' name='collection' color="#565656"></box-icon>,
    navLink: "/myspace/workspace"
  },
  {
    id: "member",
    title: "Membre",
    type: "item",
    iconName:'user-circle',
    icon: <box-icon name='user-circle' color="#565656"></box-icon> ,
    navLink: "/myspace/member"
  },
  {
    id: "facturation",
    title: "facturation",
    type: "item",
    iconName:'credit-card-front',
    icon: <box-icon name='credit-card-front' color="#565656"></box-icon>,
    navLink: "/myspace/facturation"
  }
]

export default navigationConfig
