import React from "react";
import {
	Row,
	Col,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	Button,
	Container,
	NavLink,
	Media,
} from "reactstrap";
import { ArrowRight, ChevronLeft, X } from "react-feather";
import img1 from "../../../assets/img/pages/latino_icon.png";
import { Link } from "react-router-dom";

const NewWorkSpace = () => {
	return (
		<div>
			<Container fluid>
				<Row className="d-flex justify-content-between mx-1 mt-1">
					<Link to="/myspace/workspace">
						<Button color="light" size="sm" className="px-1 py-1">
							<ChevronLeft size={20} color="white" />
						</Button>
					</Link>
					<Link to="/myspace/workspace">
						<Button color="light" size="sm" className="px-1 py-1">
							<X size={20} color="white" />
						</Button>
					</Link>
				</Row>
			</Container>
			<Container>
				<Row
					className="d-flex justify-content-center"
					style={{ marginTop: "140px" }}
				>
					<Col sm="12" className="text-center">
						<h2>Connectez-vous à votre espace de travail</h2>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center mt-1">
					<Col sm="12" md="5" className="text-center">
						<div>
							Saisissez l'<b>URL Hibim</b> de votre espace de
							travail.
						</div>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center mt-3">
					<Col sm="12" md="5">
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText className="bg-primary text-white">
									space.hibim.co/
								</InputGroupText>
							</InputGroupAddon>
							<Input
								type="text"
								name="spaceAddress"
								id="spaceAddress"
								placeholder="Adresse"
								className="form-control"
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center mt-3">
					<Col sm="12" md="5">
						<Button color="primary" style={{ width: "100%" }}>
							Continuer <ArrowRight size={10} />
						</Button>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center mt-4">
					<Col sm="12" md="5" className="text-center">
						<div>
							Vous êtes déjà connecté(e) à cet espace de travail :
						</div>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center mt-2">
					<Col sm="5" className="d-flex justify-content-center">
						<Media>
							<Media className="mr-1 align-self-center">
								<Media
									object
									width="35"
									src={img1}
									alt="Latino para siempre"
								/>
							</Media>
							<Media body>
								<Media
									heading
									className="mb-0 pb-0"
									style={{ fontSize: "1em" }}
								>
									Latino Para siempre
								</Media>
								<small>latinoparasiemprefr@gmail.com</small>
							</Media>
						</Media>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center mt-2 mb-4">
					<Col sm="12" className="text-center">
						<div>
							Besoin que votre association ou groupe à utiliser
							Hibim ?
							<NavLink className="d-inline-block" href="#">
								Créer un espace de travail Hibim
							</NavLink>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default NewWorkSpace;
